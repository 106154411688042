import React from 'react';

const Page404Component = () => {
  return (
    <div id="application-page">
      <h1>Not Found</h1>
    </div>
  );
};

export default Page404Component;
